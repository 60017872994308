<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5"> Sincronização </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="12">
        <v-btn
          type="button"
          color="error"
          @click="requestSync"
          v-auth="'SYNC.REQUEST'"
          :loading="syncList.loading"
          >Sincronizar</v-btn
        >
        <v-checkbox
          dense
          class="ml-4"
          v-model="sync.updateSubscriptionPlans"
          label="Sincronizar planos"
        ></v-checkbox>
        <v-checkbox
          dense
          mr-2
          class="ml-4"
          v-model="sync.updateSubscribers"
          label="Sincronizar assinantes"
        ></v-checkbox>
        <v-checkbox
          dense
          mr-2
          class="ml-4"
          v-model="sync.updateDevices"
          label="Sincronizar dispositivos"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              v-model="data.selectedProviders"
              item-key="id"
              show-select
              :headers="syncList.headers"
              :items="syncList.data"
              :items-per-page="-1"
              :disable-sort="true"
              :loading="syncList.loading"
              no-data-text=""
              loading-text=""
              class="app-table"
              hide-default-footer
            >
              <template v-slot:header>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th class="text-center" colspan="2">Planos</th>
                    <th class="text-center" colspan="2">Assinantes</th>
                    <th class="text-center" colspan="2">Dispositivos</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span class="list-item-title">{{ item.name }}</span>
              </template>
              <template v-slot:[`item.actions_plan`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="dashed" v-bind="attrs" v-on="on">
                      {{ item.plan.processed }}/{{ item.plan.total }}
                    </span>
                  </template>
                  <span>
                    Incluídos: <strong>{{ item.plan.total_inserted }}</strong
                    ><br />
                    Alterados: <strong>{{ item.plan.total_updated }}</strong
                    ><br />
                    Excluídos: <strong>{{ item.plan.total_deleted }}</strong>
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status_plan`]="{ item }">
                <v-tooltip top :disabled="!item.plan.description">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-inline-flex flex-column align-center">
                      <v-chip
                        small
                        :color="
                          item.plan.status
                            | enum(data.syncStatus, 'id', 'class')
                        "
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >{{ item.plan.status | enum(data.syncStatus) }}
                        <v-progress-circular
                          indeterminate
                          :width="1"
                          :size="10"
                          color="white"
                          class="ml-1"
                          v-if="item.plan.status === 'PROCESSING'"
                        ></v-progress-circular>
                      </v-chip>
                      {{ item.plan.completed_at | fromNow }}
                    </div>
                  </template>
                  <span>{{ item.plan.description }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.actions_subscriber`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="dashed" v-bind="attrs" v-on="on">
                      {{ item.subscriber.processed }}/{{
                        item.subscriber.total
                      }}
                    </span>
                  </template>
                  <span>
                    Incluídos:
                    <strong>{{ item.subscriber.total_inserted }}</strong
                    ><br />
                    Alterados:
                    <strong>{{ item.subscriber.total_updated }}</strong
                    ><br />
                    Excluídos:
                    <strong>{{ item.subscriber.total_deleted }}</strong>
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status_subscriber`]="{ item }">
                <v-tooltip top :disabled="!item.subscriber.description">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-inline-flex flex-column align-center">
                      <v-chip
                        small
                        :color="
                          item.subscriber.status
                            | enum(data.syncStatus, 'id', 'class')
                        "
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >{{ item.subscriber.status | enum(data.syncStatus)
                        }}<v-progress-circular
                          indeterminate
                          :width="1"
                          :size="10"
                          color="white"
                          class="ml-1"
                          v-if="item.subscriber.status === 'PROCESSING'"
                        ></v-progress-circular>
                      </v-chip>
                      {{ item.subscriber.completed_at | fromNow }}
                    </div>
                  </template>
                  <span>{{ item.subscriber.description }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.actions_device`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="dashed" v-bind="attrs" v-on="on">
                      {{ item.device.processed }}/{{ item.device.total }}
                    </span>
                  </template>
                  <span>
                    Incluídos: <strong>{{ item.device.total_inserted }}</strong
                    ><br />
                    Alterados: <strong>{{ item.device.total_updated }}</strong
                    ><br />
                    Excluídos: <strong>{{ item.device.total_deleted }}</strong>
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status_device`]="{ item }">
                <v-tooltip top :disabled="!item.device.description">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-inline-flex flex-column align-center">
                      <v-chip
                        small
                        :color="
                          item.device.status
                            | enum(data.syncStatus, 'id', 'class')
                        "
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >{{ item.device.status | enum(data.syncStatus) }}
                        <v-progress-circular
                          indeterminate
                          :width="1"
                          :size="10"
                          color="white"
                          class="ml-1"
                          v-if="item.device.status === 'PROCESSING'"
                        ></v-progress-circular
                      ></v-chip>
                      {{ item.device.completed_at | fromNow }}
                    </div>
                  </template>
                  <span
                    v-html="getTooltipContent(item.device.description)"
                  ></span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import syncApi from "@/common/api/sync.api";
import syncStatus from "@/common/data/sync-status.data";
import toaster from "@/common/util/toaster";
import moment from "moment";

export default {
  name: "SyncList",

  data: () => ({
    data: {
      selectedProviders: [],
      syncStatus: syncStatus,
    },

    callback: 0,
    isSyncing: true,
    sync: {
      updateSubscriptionPlans: false,
      updateSubscribers: false,
      updateDevices: true,
      providers: [],
    },
    syncList: {
      headers: [
        { text: "Provedor", value: "name" },
        {
          text: "Itens",
          value: "actions_plan",
          align: "center",
          cellClass: "text-no-wrap large",
        },
        {
          text: "Status",
          value: "status_plan",
          align: "center",
          cellClass: "text-caption",
        },
        {
          text: "Itens",
          value: "actions_subscriber",
          align: "center",
          cellClass: "text-no-wrap",
        },
        {
          text: "Status",
          value: "status_subscriber",
          align: "center",
          cellClass: "text-caption",
        },
        {
          text: "Itens",
          value: "actions_device",
          align: "center",
          cellClass: "text-no-wrap",
        },
        {
          text: "Status",
          value: "status_device",
          align: "center",
          cellClass: "text-caption",
        },
      ],
      data: [],
      loading: false,
    },
  }),

  created() {
    this.list();
  },

  beforeDestroy() {
    if (this.callback) {
      clearInterval(this.callback);
      this.callback = null;
    }
  },

  methods: {
    getTooltipContent(content) {
      let result = content;

      if (content) {
        if (Array.isArray(content)) {
          result = content.join("<br/>");
        }
      }

      return result;
    },

    refreshStatus(list) {
      let lockMinutes = 5;
      let executingStatus = false,
        executingTime = false;

      if (list) {
        let types = ["plan", "subscriber", "device"];

        executingStatus = list.some((item) =>
          types.some(
            (type) => ["WAITING", "PROCESSING"].indexOf(item[type].status) >= 0
          )
        );

        executingTime = list.some((item) =>
          types.some((type) => {
            if (item[type] && item[type].completed_at) {
              let minutes = moment
                .duration(moment().diff(moment(item[type].completed_at)))
                .asMinutes();
              return minutes < lockMinutes;
            }
            return false;
          })
        );
      }
      this.isSyncing = executingStatus && executingTime;
    },

    list() {
      syncApi.list().then((result) => {
        this.refreshStatus(result.data);
        this.syncList.data = result.data;

        if (this.callback) {
          clearInterval(this.callback);
          this.callback = null;
        }

        if (this.isSyncing) {
          this.syncList.loading = true;

          this.callback = setTimeout(() => {
            this.list();
          }, 4000);
        } else {
          this.syncList.loading = false;
        }
      });
    },

    async requestSync() {
      if (await this.$root.$confirm(true, "sync.request")) {
        if (!this.data.selectedProviders.length) {
          toaster.show("Selecione pelo menos um provedor");
          return;
        }
        this.syncList.loading = true;

        this.sync.providers =
          this.data.selectedProviders.length === this.syncList.data.length
            ? []
            : this.data.selectedProviders.map((item) => item.id);
        syncApi
          .request(
            this.sync.updateSubscriptionPlans,
            this.sync.updateSubscribers,
            this.sync.updateDevices,
            this.sync.providers.join(",")
          )
          .then((result) => {
            toaster.show(result.data._messages);

            setTimeout(() => {
              this.list();
            }, 5000);
          })
          .catch(() => (this.syncList.loading = false));
      }
    },
  },
};
</script>

<style lang="scss">
.dashed {
  border-bottom: 1px dashed #000;
}

td.large {
  height: 60px !important;
}
</style>
