import httpClient from './http-client';

const list = (): Promise<any> => {
    return httpClient.invoke('sync', 'list');
};

const request = (updateSubscriptionPlans, updateSubscribers, updateDevices, providers): Promise<any> => {
    const params = {
        query: {
            updateSubscriptionPlans,
            updateSubscribers,
            updateDevices,
            providers
        }
    };

    return httpClient.invoke('sync', 'request', params);
};

// const syncSubscriber = (provider_id, subscriber_id): Promise<any> => {
//     const params = {
//         query: {
//             provider_id,
//             subscriber_id
//         }
//     };

//     return httpClient.invoke('sync', 'syncSubscriber', params);
// };

const syncSubscriberDevices = (provider_id, subscriber_id): Promise<any> => {
    const params = {
        query: {
            provider_id,
            subscriber_id
        }
    };

    return httpClient.invoke('sync', 'syncSubscriberDevices', params);
};

export default {
    list,
    request,
    syncSubscriberDevices
}