const data = [
    {
        id: 'WAITING',
        name: 'Aguardando',
        class: 'blue-grey'
    },
    {
        id: 'PROCESSING',
        name: 'Processando',
        class: 'cyan'
    },
    {
        id: 'SYNCED',
        name: 'Sincronizado',
        class: 'green'
    },
    {
        id: 'WARNING',
        name: 'Sincronizado',
        class: 'orange'
    },
    {
        id: 'ERROR',
        name: 'Erro',
        class: 'red'
    }
];

export default [...data];